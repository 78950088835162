exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-sign-in-js": () => import("./../../../src/pages/account/sign-in.js" /* webpackChunkName: "component---src-pages-account-sign-in-js" */),
  "component---src-pages-authorization-js": () => import("./../../../src/pages/authorization.js" /* webpackChunkName: "component---src-pages-authorization-js" */),
  "component---src-pages-awesome-mdx": () => import("./../../../src/pages/awesome.mdx" /* webpackChunkName: "component---src-pages-awesome-mdx" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-contact-js": () => import("./../../../src/pages/company/contact.js" /* webpackChunkName: "component---src-pages-company-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labs-js": () => import("./../../../src/pages/labs.js" /* webpackChunkName: "component---src-pages-labs-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-disclaimer-js": () => import("./../../../src/pages/legal/disclaimer.js" /* webpackChunkName: "component---src-pages-legal-disclaimer-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-and-conditions-js": () => import("./../../../src/pages/legal/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-js" */),
  "component---src-pages-legal-terms-of-service-mdx": () => import("./../../../src/pages/legal/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-legal-terms-of-service-mdx" */),
  "component---src-pages-legal-user-data-deletion-mdx": () => import("./../../../src/pages/legal/user-data-deletion.mdx" /* webpackChunkName: "component---src-pages-legal-user-data-deletion-mdx" */),
  "component---src-pages-partners-glaxf-voice-activity-detection-js": () => import("./../../../src/pages/partners/glaxf/voice-activity-detection.js" /* webpackChunkName: "component---src-pages-partners-glaxf-voice-activity-detection-js" */),
  "component---src-pages-plans-and-pricing-js": () => import("./../../../src/pages/plans-and-pricing.js" /* webpackChunkName: "component---src-pages-plans-and-pricing-js" */),
  "component---src-pages-web-extension-addon-js": () => import("./../../../src/pages/web-extension-addon.js" /* webpackChunkName: "component---src-pages-web-extension-addon-js" */),
  "component---src-pages-web-extension-addon-real-time-demo-js": () => import("./../../../src/pages/web-extension-addon/real-time-demo.js" /* webpackChunkName: "component---src-pages-web-extension-addon-real-time-demo-js" */),
  "component---src-pages-web-extension-addon-roadmap-js": () => import("./../../../src/pages/web-extension-addon/roadmap.js" /* webpackChunkName: "component---src-pages-web-extension-addon-roadmap-js" */)
}

