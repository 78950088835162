import { createSlice } from '@reduxjs/toolkit';

import PlaylistPlayerService from '../../api-local/player/PlaylistPlayerService';

import PlaylistItem from '../../domain/playlist/PlaylistItem';
import type { RootState } from '..';

interface PlaylistState {
  items: PlaylistItem[];
  selectedIndex: number;
  isPlaying: boolean;
}

const initialState: PlaylistState = {
  items: [],
  selectedIndex: 0,
  isPlaying: false,
};

export const playlistSlice = createSlice({
  name: `playlist`,
  initialState,
  reducers: {
    addPlaylistItem: (
      state: PlaylistState,
      action: { payload: any; type: string }
    ) => {
      state.items = [...state.items, action.payload];
    },
    play: (state: PlaylistState) => {
      // console.log(`PLAYLIST - play`, state.selectedIndex);
      PlaylistPlayerService.getInstance().play(state.selectedIndex);
      state.isPlaying = true;
    },
    pause: (state: PlaylistState) => {
      // console.log(`PLAYLIST - pause`, state.selectedIndex);
      PlaylistPlayerService.getInstance().pause(state.selectedIndex);
      state.isPlaying = false;
    },
    increaseSelectedIndex: (state: PlaylistState) => {
      let newIndex = state.selectedIndex + 1;
      if (newIndex > state.items.length - 1) {
        newIndex = 0;
      }
      // console.log(`increaseSelectedIndex`, newIndex);
      state.selectedIndex = newIndex;
    },
    decreaseSelectedIndex: (state: PlaylistState) => {
      let newIndex = state.selectedIndex - 1;
      if (newIndex < 0) {
        newIndex = state.items.length - 1;
      }
      // console.log(`decreaseSelectedIndex`, newIndex);
      state.selectedIndex = newIndex;
    },
    setSelectedIndex: (
      state: PlaylistState,
      action: { payload: any; type: string }
    ) => {
      // console.log(`PLAYLIST - setSelectedIndex`, action.payload.index);
      state.selectedIndex = action.payload.index;
    },
  },
});

export const {
  addPlaylistItem,
  increaseSelectedIndex,
  decreaseSelectedIndex,
  setSelectedIndex,
  play,
  pause,
} = playlistSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectPlaylistItems = (state: RootState) => state.playlist.items;

export const selectSelectedIndex = (state: RootState) =>
  state.playlist.selectedIndex;

export const selectIsPlaying = (state: RootState) => state.playlist.isPlaying;

export default playlistSlice;
