import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import firebase from '../../FirebaseConfig';

const RootContext = React.createContext({});

const RootContextProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  const showSidebar = () => {
    setIsSidebarOpen(true);
  };

  const hideSidebar = () => {
    setIsSidebarOpen(false);
  };

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        // console.log(
        //   `RootContextProvider - onAuthStateChanged firebase.auth().currentUser:`,
        //   firebase.auth().currentUser
        // );

        if (user) {
          // console.log(`Setting User Cookie...`);
          setCookie('user', user, { path: '/' });
        } else {
          // console.log(`Removing User Cookie...`);
          removeCookie('user', { path: '/' });
        }

        setCurrentUser(firebase.auth().currentUser);

        // setReadyToRender(true);
      });
    return () => {
      // console.log(`unregisterAuthObserver()`);
      unregisterAuthObserver();
    }; // Make sure we un-register Firebase observers when the component unmounts.
  }, [setCookie, removeCookie]);

  return (
    <RootContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        isSidebarOpen,
        showSidebar,
        hideSidebar,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export { RootContext, RootContextProvider };
