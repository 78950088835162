import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { RootContextProvider } from '../../src/app/contexts/RootContextProvider';

import store from '../../src/store';
import theme from '../../src/ui/themes/dark';

// import '../../src/ui/styles/font-5x8-lcd.css';
// import '../../src/ui/styles/font-aria3d.css';
// import '../../src/ui/styles/font-poppins.css';
// import '../../src/ui/styles/font-roboto-condensed.css';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <RootContextProvider>{props.children}</RootContextProvider>
        </Provider>
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
