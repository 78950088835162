import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./reducers/globals";
import playlistSlice from "./reducers/playlist";

export const store: any = configureStore({
  reducer: {
    globals: globalSlice.reducer,
    playlist: playlistSlice.reducer,
  },
  devTools: !!(process.env.NODE_ENV && process.env.NODE_ENV === "development"),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
