import {
  ApiRegistry,
  IMediaContent,
  RemoteAudioMediaContent,
  RemoteVideoMediaContent,
} from 'shared';
import PlaylistItem from '../../domain/playlist/PlaylistItem';

export default class PlaylistPlayerService {
  // ===============================================================================================
  // STATE
  // ===============================================================================================

  private static instance: PlaylistPlayerService;

  // ===============================================================================================
  // SINGLETON
  // ===============================================================================================

  public static getInstance(): PlaylistPlayerService {
    if (!PlaylistPlayerService.instance) {
      // console.log(`New PlaylistPlayerService`)
      PlaylistPlayerService.instance = new PlaylistPlayerService();
    }

    return PlaylistPlayerService.instance;
  }

  // ===============================================================================================
  // STATE
  // ===============================================================================================
  private mediaContentArray: IMediaContent[];
  private playerId: string;

  // ===============================================================================================
  // CONSTRUCTOR
  // ===============================================================================================
  private constructor() {
    this.mediaContentArray = [];
    this.playerId = `website`;
  }

  // ===============================================================================================
  // METHODS
  // ===============================================================================================

  public assignPlaylistItems(playlistItems: PlaylistItem[]) {
    this.mediaContentArray =
      this.convertPlaylistItemsToMediaContents(playlistItems);
  }

  public play(selectedIndex: number) {
    ApiRegistry.getInstance().backgroundMediaPlayerService.play(
      this.playerId,
      this.mediaContentArray[selectedIndex],
      selectedIndex
    );
  }

  public pause(selectedIndex: number) {
    ApiRegistry.getInstance().backgroundMediaPlayerService.pause(
      this.playerId,
      selectedIndex
    );
  }

  // ===============================================================================================
  // PRIVATE
  // ===============================================================================================
  public convertPlaylistItemsToMediaContents = (
    playlistItems: PlaylistItem[]
  ) => {
    const mediaContents: IMediaContent[] = [];
    playlistItems.forEach((playlistItem) => {
      let mediaContent: IMediaContent;

      if (playlistItem.path.endsWith(`mp4`)) {
        mediaContent = new RemoteVideoMediaContent(
          playlistItem.path,
          playlistItem.title,
          ``
        );
      } else {
        mediaContent = new RemoteAudioMediaContent(
          playlistItem.path,
          playlistItem.title,
          ``
        );
      }

      mediaContents.push(mediaContent);
    });

    return mediaContents;
  };
}
